import React from "react";


function GiantTeam(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
    }
    let urlGFX = null;
    if (props.specificGFX){
        urlGFX = '/img/events/'+idArena+'/giant_full/'
    } else {
        urlGFX = '/img/giant_full/'
    }     
    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%',backgroundImage: "url(" + urlGFX + "team.png)"}}>
                {datas && datas.team ?
                <>
                <div className="giant_full-team-title">
                    <div className="giant_full-team-title-text">
                        Team {datas.team.name}
                    </div>
                </div>
                <div className="giant_full-team-flag">
                        {datas.team.nation &&
                            <img alt="drapeau" width="200px" src={"/img/flags/"+datas.team.nation+'.png'}/>
                        }
                </div>
                <div className="giant_full-team-total">
                    <div className="giant_full-team-total-text">
                        Total
                    </div>
                    <div className="giant_full-team-points-text">
                        {datas.team.total}
                    </div>
                    <div className="giant_full-team-rank-text">
                        {datas.team.rank>0 && <>Rank: {datas.team.rank}</>}
                    </div>
                </div>

                <div className="giant_full-team-lines">
                    {datas.team.members.map((d,index)=>{
                            return(
                                <div key={index} className="giant_full-team-line" style={{ backgroundRepeat:'no-repeat'}}>
                                    <div className="giant_full-ranking-line-rank">
                                        
                                    </div>
                                    <div className="giant_full-team-line-rider">
                                        {d.rider}
                                    </div>
                                    <div className="giant_full-team-line-round1">
                                        <>{d.discard1>0 && '('}{d.etat1?d.etat1:d.pen1}{d.discard1>0 && ')'}</>
                                    </div>
                                    <div className="giant_full-team-line-round2">
                                        <>{d.discard2>0 && '('}{d.etat2?d.etat2:d.pen2}{d.discard2>0 && ')'}</>
                                    </div>
                                    <div className="giant_full-ranking-flag">

                                    </div>
                                </div>        
                            )        
                        
                    })}
                </div> 
                </>   
                :null}    
            </div>
        </>
      );
  }

  export default GiantTeam;
