import React, { useState, useEffect, useRef } from "react";
import Badge from 'react-bootstrap/Badge';
import {applyFontStretch} from '../../functions/strech.js'

function GiantSo01(props) {
    if (props){
        var idArena = props.idArena;
        var datas = props.datas;
        var datasTiming = props.datasTiming;
        var datasStats = props.datasStats;
        if (props.specificGFX){
            var urlGFX = '/img/events/'+idArena+'/giant/'
        } else {
            var urlGFX = '/img/giant/'
        }     
    }

    useEffect(() => {
        applyFontStretch(".giant-rider")
        applyFontStretch(".giant-horse")
    },[datas])

    return (
        <>
            <div style={{ backgroundColor: "transparent",height:'100%',width:'100%'}}>
                {datas.live &&
                <>
                <div className="giant-presentation" style={{ backgroundImage: "url("+urlGFX+"scb-presentation-background.png)"}}>
                    <div className="giant-rider">
                        <p>{datas.live.rider}</p>
                    </div>
                    <div className="giant-horse">
                        <p>{datas.competition.isInter ? datas.live.order : datas.live.hnb} - {datas.live.horse}</p>
                    </div>
                    <div className="giant-flag">
                        {datas.live.nation &&
                            <img alt="drapeau" width="250px" src={"/img/flags/"+datas.live.nation+'.png'}/>
                        }
                    </div>
                </div>
                {datas.live.results.totalPen == 'RET' || datas.live.results.totalPen == 'EL' ?
                    <div className="giant-rank" style={{ backgroundImage: "url("+urlGFX+"scb-champ-background.png)"}}>
                        <div className="giant-rank-text">
                            {datas.live.results.totalPen}
                        </div>
                    </div>
                :
                <>
                <div className="giant-chrono" style={{ backgroundImage: "url("+urlGFX+"scb-time-background.png)"}}>
                    <div className="giant-chrono-text">
                        {datasTiming.countDown ? datasTiming.countDownValue : datasTiming.time}
                    </div>
                </div>
                {datasTiming.countDownValue || datasTiming.time=="-45.00"? null : 
                    <div className="giant-points" style={{ backgroundImage: "url("+urlGFX+"scb-pen-background.png)"}}>
                        <div className="giant-points-text">
                            {datasTiming.totalFaults}
                        </div>
                    </div>
                }
                {datas.live.results.entryRank && <>
                    <div className="giant-rank" style={{ backgroundImage: "url("+urlGFX+"scb-champ-background.png)"}}>
                        <div className="giant-rank-text">
                        {datas.live.results.extraPoints ?
                            <>
                                {datas.live.results.extraPoints}
                            </>:<>
                            {datas.live.results.entryRank ? <>{'Rank: '+datas.live.results.entryRank+'.'}</>:null}
                            </>
                        }
                        </div>
                    </div>
                </>}
                
                {datas.timeDiff && 
                    <>
                    {datas.timeDiff > 0 ? 
                    <>
                        <div className="giant-ecart" style={{ backgroundImage: "url("+urlGFX+"scb-ecart-rouge.png)"}}>
                            <div className="giant-ecart-text">
                                {'+'+datas.timeDiff}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="giant-ecart" style={{ backgroundImage: "url("+urlGFX+"scb-ecart-vert.png)"}}>
                            <div className="giant-ecart-text">
                                {datas.timeDiff}
                            </div>
                        </div>                    
                    </>} 
                    </>
                }

                {datas.ecurieGN && 
                <>
                    <div className="giant-gn" style={{ backgroundImage: "url("+urlGFX+"scb-gn.png)"}}>
                        <div className="giant-gn-text">
                            {datas.ecurieGN.libelle}
                        </div>
                        <img className="giant-gn-logo" alt="ecurieGN" width="100%" src={"/img/ecuries/so/"+datas.ecurieGN.num+'.png'}/>
                    </div>                    
                </>
                }
                </>                
                }
                </>
                }
                {(datasStats && datasStats.alogo && datasStats.alogo.live_tv) &&
                <>
                    <div className="giant-ecartlive-lines">
                        {datasStats.alogo.live_tv.ecart3 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart3.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    3
                                </div>
                                <div className="giant-ecartlive-text">
                                    {datasStats.alogo.live_tv.ecart3.LiveValue>0&&'+'}{datasStats.alogo.live_tv.ecart3.LiveValue}
                                </div>
                            </div>  
                        } 
                        {datasStats.alogo.live_tv.ecart2 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live_tv.ecart2.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    2
                                </div>
                                <div className="giant-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart2.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart2.LiveValue}
                                </div>
                            </div>  
                        }   
                        {datasStats.alogo.live_tv.ecart1 && 
                            <div className="giant-ecartlive" style={{ backgroundImage: datasStats.alogo.live.ecart1.LiveValue>0?"url(" + urlGFX+"scb-ecart-live-rouge.png)":"url(" + urlGFX+"scb-ecart-live-vert.png)"}}>
                                <div className="giant-ecartlive-title">
                                    1
                                </div>
                                <div className="giant-ecartlive-text">
                                {datasStats.alogo.live_tv.ecart1.LiveValue>0 &&'+'}{datasStats.alogo.live_tv.ecart1.LiveValue}
                                </div>
                            </div>  
                        }                                               
                    </div>
                </>
                }
            </div>
        </>
      );
  }

  export default GiantSo01;
